import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  topWebsiteData: [],
};
const TopWebReducer = createSlice({
  name: "topWebsites",
  initialState: InitialState,
  reducers: {
    topTenRecord(state, action) {
      state.topWebsiteData = action.payload;
    },
    likeDataUpdatePaidCard(state, action) {
      const allUpdatedDataWebsite = state.topWebsiteData?.map((obj) => {
        if (obj?.websiteId === action.payload?.id) {
          let a = {
            ...obj,
            isLike: action.payload?.isLike,
            likeCount: action.payload?.likeCount,
          };
          return a;
        } else {
          return obj;
        }
      });
      state.topWebsiteData = allUpdatedDataWebsite;
    },
    collectionDataUpdateTopTen(state, action) {
      const allUpdatedDataWebsite = state.topWebsiteData?.map((obj) => {
        if (obj?.websiteId === action.payload?.id) {
          let a = {
            ...obj,
            isCollection: action.payload?.isCollection,
          };
          return a;
        } else {
          return obj;
        }
      });
      state.topWebsiteData = allUpdatedDataWebsite;
    },
  },
});

export default TopWebReducer.reducer;
export const {
  topTenRecord,
  likeDataUpdatePaidCard,
  collectionDataUpdateTopTen,
} = TopWebReducer.actions;
