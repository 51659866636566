import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signInEmail: [],
  signInData: {},
  token: null,
};
const AuthReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInEmail: (state, action) => {
      state.signInEmail = action.payload;
    },
    signInData: (state, action) => {
      state.signInData = action.payload;
    },
    token: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { signInEmail, signInData, token } = AuthReducer.actions;
export default AuthReducer.reducer;
