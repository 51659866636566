import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userPortfolioProfileData: {},
  userPortfolioPopularPostData: [],
  userTotalLikes: 0,
  portfolioWebsitesData: [],
  portfolioWebsitesIsEndPage: false,
};
const UserPortfolioReducer = createSlice({
  name: "userPortfolio",
  initialState,
  reducers: {
    PortfolioProfileData: (state, action) => {
      state.userPortfolioProfileData = action.payload;
    },
    userPortfolioPopularPost: (state, action) => {
      state.userPortfolioPopularPostData = action.payload;
    },
    userPortfolioTotalLikes: (state, action) => {
      state.userTotalLikes = action.payload;
    },
    PortfolioWebsites: (state, action) => {
      state.portfolioWebsitesData = action.payload;
    },
    isPortfolioWebsitesEndPage: (state, action) => {
      state.portfolioWebsitesIsEndPage = action.payload;
    },
    PortfolioLikeUpdate: (state, action) => {
      const allUpdatedDataWebsite = state.portfolioWebsitesData?.map((obj) => {
        if (obj?.websiteId === action.payload?.id) {
          let updatedData = {
            ...obj,
            isLike: action.payload?.isLike,
            likeCount: action.payload?.likeCount,
          };
          return updatedData;
        } else {
          return obj;
        }
      });
      state.portfolioWebsitesData = allUpdatedDataWebsite;
    },
    PortfolioCollectionUpdate: (state, action) => {
      const allUpdatedDataWebsite = state.portfolioWebsitesData?.map((obj) => {
        if (obj?.websiteId === action.payload?.id) {
          let updatedData = {
            ...obj,
            isCollection: action.payload?.isCollection,
          };
          return updatedData;
        } else {
          return obj;
        }
      });
      state.portfolioWebsitesData = allUpdatedDataWebsite;
    },
  },
});

export const {
  PortfolioProfileData,
  userPortfolioPopularPost,
  userPortfolioTotalLikes,
  PortfolioWebsites,
  isPortfolioWebsitesEndPage,
  PortfolioLikeUpdate,
  PortfolioCollectionUpdate,
} = UserPortfolioReducer.actions;
export default UserPortfolioReducer.reducer;
