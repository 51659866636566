"use client";
import React, { createContext, useContext, useRef, useState } from "react";
import Head from "next/head";

const NavContext = createContext(undefined);

export function NavProvider({ children }) {
  const [isAuthModal, setIsAuthModal] = useState(false);
  const [fontFamilyValue, setFontFamilyValue] = useState({
    isFontFamily: false,
    fontFamilyName: "Poppins",
  });
  const [isSearch, setIsSearch] = useState(false);
  const [isScreenShotOpen, setIsScreenShotOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [open, setOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState({
    popular: false,
    typography: false,
    country: false,
  });
  const filterRef = useRef(null);
  const [filterValueJson, setFilterValueJson] = useState({
    category: "",
    style: "",
    font: "",
    color: "",
    country: "",
    orderBy: "",
    search: "",
  });
  const [loadingWebsite, setLoadingWebsite] = useState(true);
  return (
    <NavContext.Provider
      value={{
        filterRef,
        isAuthModal,
        fontFamilyValue,
        isSearch,
        filterValue,
        open,
        isDrawerOpen,
        isScreenShotOpen,
        filterValueJson,
        loadingWebsite,
        setLoadingWebsite,
        setFilterValueJson,
        setFilterValue,
        setIsSearch,
        setIsAuthModal,
        setFontFamilyValue,
        setOpen,
        setIsDrawerOpen,
        setIsScreenShotOpen,
      }}
    >
      <Head>
        <link
          href={`https://fonts.googleapis.com/css?family=${fontFamilyValue?.fontFamilyName}`}
          rel="stylesheet"
        ></link>
      </Head>
      {children}
    </NavContext.Provider>
  );
}

export function useNav() {
  const context = useContext(NavContext);

  return context;
}
