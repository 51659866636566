import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  allNotifications: [],
  notificationId: "",
  notificationCount: 0,
};
const NotificationReducer = createSlice({
  name: "notification",
  initialState: InitialState,
  reducers: {
    notificationsId(state, action) {
      state.notificationId = action?.payload;
    },
    allNotificationsRec(state, action) {
      state.allNotifications = action?.payload;
    },
    addNotification(state, action) {
      state.allNotifications = [action.payload, ...state.allNotifications];
    },
    deleteNotification(state, action) {
      state.allNotifications = state.allNotifications?.filter(
        (obj) => obj?.NotificationId !== action?.payload
      );
    },
    deleteAllNotification(state, action) {
      state.allNotifications = state.allNotifications?.filter(
        (obj) => !action?.payload.includes(obj?.NotificationId)
      );
    },
    notificationCountData(state, action) {
      state.notificationCount = action?.payload?.filter((item) => item?.isRead);
    },
  },
});

export default NotificationReducer.reducer;
export const {
  notificationsId,
  allNotificationsRec,
  addNotification,
  deleteNotification,
  deleteAllNotification,
  notificationCountData,
} = NotificationReducer.actions;
