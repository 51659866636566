import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  profileData: [],
  userWebsiteData: [],
  userFilterWebData: [],
  isMyWebsiteLastPage: false,
  isLoadingMyWebsite: true,
  profileModuleName: "Profile",
};
const ProfileDataReducer = createSlice({
  name: "profile",
  initialState: InitialState,
  reducers: {
    userProfileData(state, action) {
      state.profileData = action.payload;
    },
    userUpdateProfileData(state, action) {
      state.profileData = {
        ...state.profileData,
        firstName: action?.payload?.firstName,
        lastName: action?.payload?.lastName,
        country: action?.payload?.country,
        phoneNo: action?.payload?.phoneNo,
        calendlyURL: action?.payload?.calendlyURL,
        personalWebsite: action?.payload?.personalWebsite,
        portfolioURL: action?.payload?.portfolioURL,
        userName: action?.payload?.userName,
        profileImg: action?.payload?.profileImg,
        socialSite: action?.payload?.socialSite,
        Bio: action?.payload?.Bio,
        portfolioTitle: action?.payload?.portfolioTitle,
      };
    },
    getUserWebsiteDetails(state, action) {
      state.userWebsiteData = action.payload;
    },
    userFilterWebsiteData(state, action) {
      state.userFilterWebData = action.payload;
    },
    userWebsiteDeleteRecord(state, action) {
      state.userWebsiteData = state.userWebsiteData?.filter(
        (obj) => obj?.websiteId !== action.payload
      );
      state.userFilterWebData = state.userFilterWebData?.filter(
        (obj) => obj?.websiteId !== action.payload
      );
    },
    userWebsiteUpdateRecord(state, action) {
      state.userWebsiteData = state.userWebsiteData?.filter(
        (obj) => obj?.websiteId !== action?.payload?.websiteId
      );
    },
    userWebsiteFilterUpdateRecord(state, action) {
      state.userFilterWebData = state.userFilterWebData?.filter(
        (obj) => obj?.websiteId !== action?.payload?.websiteId
      );
    },
    likeUserWebsiteDataUpdate(state, action) {
      state.userWebsiteData = action?.payload;
    },
    isMyWebsiteEndPage(state, action) {
      state.isMyWebsiteLastPage = action?.payload;
    },
    isMyWebsiteLoaidng(state, action) {
      state.isLoadingMyWebsite = action.payload;
    },
    profileModuleChange(state, action) {
      state.profileModuleName = action.payload;
    },
  },
});

export default ProfileDataReducer.reducer;
export const {
  userProfileData,
  userUpdateProfileData,
  getUserWebsiteDetails,
  userWebsiteDeleteRecord,
  userWebsiteUpdateRecord,
  likeUserWebsiteDataUpdate,
  userFilterWebsiteData,
  userWebsiteFilterUpdateRecord,
  isMyWebsiteEndPage,
  isMyWebsiteLoaidng,
  profileModuleChange,
} = ProfileDataReducer.actions;
