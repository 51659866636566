import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  settingModuleName: "General",
  socialProfileData: [],
  sessionDetails: [],
};
const SettingsReducer = createSlice({
  name: "settings",
  initialState: InitialState,
  reducers: {
    settingsModuleChange(state, action) {
      state.settingModuleName = action.payload;
    },
    allSocialProfile(state, action) {
      state.socialProfileData = action.payload;
    },
    getSessionDetails(state, action) {
      state.sessionDetails = action.payload;
    },
  },
});

export default SettingsReducer.reducer;
export const { settingsModuleChange, allSocialProfile, getSessionDetails } =
  SettingsReducer.actions;
