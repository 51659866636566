import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  websiteData: [],
  categoryFilter: [],
  searchRec: [],
  websitesFilter: {},
  isWebsiteAllDataLastPage: false,
  isLoadingWebsiteState: true,
  userNameApplied: "",
};
const WebsiteReducer = createSlice({
  name: "website",
  initialState: InitialState,
  reducers: {
    websites(state, action) {
      state.websiteData = action.payload;
    },
    websiteSearchData(state, action) {
      state.searchRec = action?.payload[0];
    },
    likeDataUpdate(state, action) {
      const allUpdatedDataWebsite = state.websiteData?.map((obj) => {
        if (obj?.websiteId === action.payload?.id) {
          let updatedData = {
            ...obj,
            isLike: action.payload?.isLike,
            likeCount: action.payload?.likeCount,
          };
          return updatedData;
        } else {
          return obj;
        }
      });
      state.websiteData = allUpdatedDataWebsite;
    },
    websiteCollectionUpdate(state, action) {
      const allUpdatedDataWebsite = state.websiteData?.map((obj) => {
        if (obj?.websiteId === action.payload?.id) {
          let updatedData = {
            ...obj,
            isCollection: action.payload?.isCollection,
          };
          return updatedData;
        } else {
          return obj;
        }
      });
      state.websiteData = allUpdatedDataWebsite;
    },
    categoryCarouselFilter(state, action) {
      state.categoryFilter = action.payload;
    },
    categoryCarouselUpdate(state, action) {
      const allUpdatedDataWebsite = state.categoryFilter?.map((obj) => {
        if (obj?.websiteId === action.payload?.id) {
          let updatedData = {
            ...obj,
            isLike: action.payload?.isLike,
            likeCount: action.payload?.likeCount,
          };
          return updatedData;
        } else {
          return obj;
        }
      });
      state.categoryFilter = allUpdatedDataWebsite;
    },
    categoryCarouselCollection(state, action) {
      const allUpdatedDataWebsite = state.categoryFilter?.map((obj) => {
        if (obj?.websiteId === action.payload?.id) {
          let updatedData = {
            ...obj,
            isCollection: action.payload?.isCollection,
          };
          return updatedData;
        } else {
          return obj;
        }
      });
      state.categoryFilter = allUpdatedDataWebsite;
    },
    filterWebsiteById(state, action) {
      state.websitesFilter = action.payload;
    },
    likeDataFilterUpdate(state, action) {
      state.websitesFilter = action.payload;
    },
    saveDataFilterUpdate(state, action) {
      state.websitesFilter = action.payload;
    },
    isAllWebsiteEndPage(state, action) {
      state.isWebsiteAllDataLastPage = action.payload;
    },
    isLoadingWebsite(state, action) {
      state.isLoadingWebsiteState = action.payload;
    },
    setIsUserNameApplied(state, action) {
      state.userNameApplied = action.payload;
    },
  },
});

export default WebsiteReducer.reducer;
export const {
  websites,
  websiteSearchData,
  likeDataUpdate,
  categoryCarouselFilter,
  filterWebsiteById,
  likeDataFilterUpdate,
  categoryCarouselUpdate,
  websiteCollectionUpdate,
  categoryCarouselCollection,
  saveDataFilterUpdate,
  isAllWebsiteEndPage,
  isLoadingWebsite,
  setIsUserNameApplied,
} = WebsiteReducer.actions;
