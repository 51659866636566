import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  manageCollection: {},
};
const CollectionReducer = createSlice({
  name: "collection",
  initialState: InitialState,
  reducers: {
    manageCollections(state, action) {
      state.manageCollection = action.payload;
    },
    collectionLikeUpdate(state, action) {
      const updatedWebsite = action.payload;
      const updatedCollection = state.manageCollection[
        updatedWebsite?.collectionsName
      ]?.map((obj) => {
        if (obj?.websiteId === updatedWebsite?.websiteId) {
          return updatedWebsite;
        } else {
          return obj;
        }
      });
      const updatedManageCollection = {
        ...state.manageCollection,
        [updatedWebsite?.collectionsName]: updatedCollection,
      };
      state.manageCollection = updatedManageCollection;
    },
    deleteCollection(state, action) {
      const keyToDelete = action.payload;
      const manageCollection = { ...state.manageCollection };
      delete manageCollection[keyToDelete];
      return {
        ...state,
        manageCollection,
      };
    },
  },
});

export default CollectionReducer.reducer;
export const { manageCollections, collectionLikeUpdate, deleteCollection } =
  CollectionReducer.actions;
