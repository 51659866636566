import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryData: [],
  stylesData: [],
  fontFamilyData: [],
};
const CategoryAndStyleReducer = createSlice({
  name: "categoryAndStyles",
  initialState,
  reducers: {
    categoryList: (state, action) => {
      state.categoryData = action.payload;
    },
    styleList: (state, action) => {
      state.stylesData = action.payload;
    },
    getAllFontFamily: (state, action) => {
      state.fontFamilyData = action.payload;
    },
  },
});

export const { categoryList, styleList, getAllFontFamily } =
  CategoryAndStyleReducer.actions;
export default CategoryAndStyleReducer.reducer;
